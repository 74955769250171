import { CalendarMonth, DayPicker } from "react-day-picker";
import { nl } from "react-day-picker/locale";
import "react-day-picker/style.css";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setDate } from "../appointmentSlice";
import { format } from "date-fns";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { setGeneralStep } from "../../helpType/helpTypeSlice";
import { GeneralStepsEnum } from "../../../common/constants/GeneralStepsEnum";

const AppointmentDayPicker = () => {
  const { dossier } = useAppSelector((state) => state.root);
  const { agendaMoments } = useAppSelector((state) => state.appointment);
  const { activeAgendaMoment } = agendaMoments;
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<Date>();
  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  }, [activeAgendaMoment]);

  const handleDayClick = (day: Date) => {
    dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
    dispatch(setDate(format(day, "yyyy-MM-dd")));
  };

  if (
    activeAgendaMoment &&
    activeAgendaMoment.availability &&
    activeAgendaMoment.availability?.dates &&
    dossier
  ) {
    if (agendaMoments) {
      const disabledDays = activeAgendaMoment.availability.dates
        .filter((availability) => availability.has_capacity)
        .map((availabilityItem) => {
          return new Date(availabilityItem.date);
        });

      const isDayDisabled = (day: Date) => {
        return !disabledDays.some(
          (disabledDay) => day.toDateString() === disabledDay.toDateString(),
        );
      };

      return (
        <div
          className="s-appointment__group border-color-primary no-padding"
          ref={scrollRef}
        >
          <h1 className="s-appointment__group__title">
            Selecteer voorkeursdatum
          </h1>
          <p className="small s-appointment__datepicker__text">
            <b>Let op:</b> je geeft hiermee een voorkeursdatum op. De vestiging
            neemt nog contact met je op om de afspraak te bevestigen of om een
            andere datum met je te plannen.
          </p>
          <div
            className="s-appointment__datepicker"
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "25rem",
              borderRadius: ".5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DayPicker
              mode="single"
              selected={selected}
              onSelect={setSelected}
              defaultMonth={moment(
                activeAgendaMoment.availability.firstOption.day.date,
              ).toDate()}
              disabled={isDayDisabled}
              locale={nl}
              onDayClick={handleDayClick}
              components={{
                Nav: Nav,
                MonthCaption: MonthCaption,
                Weekdays: WeekDays,
              }}
            />
          </div>
        </div>
      );
    } else return null;
  } else return null;
};

interface NavProps {
  onPreviousClick: () => void;
  onNextClick: () => void;
  previousMonth: Date;
}

const Nav = ({ onPreviousClick, onNextClick, previousMonth }: NavProps) => {
  const { agendaMoments } = useAppSelector((state) => state.appointment);
  const { activeAgendaMoment } = agendaMoments;
  const thisMonth = moment(previousMonth).add(1, "month").toDate();
  return (
    <div className="DayPicker-NavBar">
      {moment(thisMonth).isAfter(
        moment(activeAgendaMoment?.availability.firstOption.day.date).toDate(),
      ) && (
        <button className="button-prev" onClick={onPreviousClick}>
          <i className={"icon-circle-button-left color-primary"} />
        </button>
      )}
      <button className="button-next" onClick={onNextClick}>
        <i className={"icon-circle-button-right color-primary"} />
      </button>
    </div>
  );
};

const MonthCaption = ({ calendarMonth }: { calendarMonth: CalendarMonth }) => {
  const date = new Date(calendarMonth.date);
  const monthAndYearInDutch = format(date, "MMMM yyyy", { locale: nl });

  return (
    <div className="DayPicker-Caption">
      <div>{monthAndYearInDutch}</div>
    </div>
  );
};

const WeekDays = () => {
  const days = moment.weekdaysShort(true); // true parameter sorts them by locale

  return (
    <thead className="DayPicker-Weekdays">
      <tr className="DayPicker-WeekdaysRow">
        {days.map((day, index) => (
          <th key={index} className="DayPicker-Weekday">
            <abbr>{day}</abbr>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default AppointmentDayPicker;
