import {
  setInsuranceActiveContractor,
  setMissingContractor,
  setMissingContractorName,
  setPostMissingContractor,
} from "../vehicleInformationSlice";
import { useGetContractorsByTypeQuery } from "../../apiData/apiDataSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import React, { useEffect, useRef } from "react";
import { setDossierLoading, updateDossier } from "../../../app/rootReducer";
import ContractorInterface from "../../../common/interfaces/ContractorInterface";
import { PatchStepsEnum } from "../../../common/constants/PatchStepsEnum";
import { setGeneralStep } from "../../helpType/helpTypeSlice";
import { GeneralStepsEnum } from "../../../common/constants/GeneralStepsEnum";
import { unsetAppointment } from "../../appointment/appointmentSlice";
import { postMissingContractorName } from "../../../common/api/Dossier/postMissingContractorMail";
import { useParams } from "react-router-dom";
import FormSelectFuzzy from "../../../common/components/form/FormSelectFuzzy";

const VehicleInformationInsurance = () => {
  const dispatch = useAppDispatch();

  const { slug } = useParams();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { insurance, fundingType } = useAppSelector(
    (state) => state.vehicleInformation,
  );
  const { dossier } = useAppSelector((state) => state.root);
  const { missingContractor, missingContractorName, postMissingContractor } =
    useAppSelector((state) => state.vehicleInformation);
  const { data: contractors, isLoading } = useGetContractorsByTypeQuery({
    type: "insurance",
    dossierId: dossier?.dossier_id ?? 0,
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(setDossierLoading(true));
    } else {
      dispatch(setDossierLoading(false));
    }
  }, [isLoading]);

  const renderCondition = dossier && dossier.car_brand && dossier.car_model;
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  }, [
    fundingType,
    insurance.activeContractorId,
    contractors,
    missingContractor,
  ]);

  const handleContractorClick = (activeContractorId: number) => {
    dispatch(setGeneralStep(GeneralStepsEnum.Insurance));
    dispatch(unsetAppointment());

    if (typeof activeContractorId !== "undefined" && activeContractorId) {
      dispatch(
        updateDossier({
          data: { contractor_id: activeContractorId },
          method: PatchStepsEnum.PatchContractor,
        }),
      ).then(() => {
        dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
      });
    }
  };

  const handleContinueWithoutContractor = () => {
    dispatch(setMissingContractor(true));
  };

  const handleMissingContractorNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setMissingContractorName(event.target.value));
    dispatch(setPostMissingContractor(false));
  };

  const handleMissingContractorSubmit = () => {
    if (postMissingContractor || isSubmitting) return;
    setIsSubmitting(true);
    const localDossierUuidKey = slug + "-dossier_uuid";
    const localDossierAccessTokenKey = slug + "-dossier_access_token";
    const uuid = localStorage.getItem(localDossierUuidKey) + "";
    const accessToken = localStorage.getItem(localDossierAccessTokenKey) + "";

    dispatch(setGeneralStep(GeneralStepsEnum.Insurance));
    dispatch(unsetAppointment());
    dispatch(setGeneralStep(GeneralStepsEnum.Appointment));

    dispatch(
      postMissingContractorName({
        name: missingContractorName,
        uuid,
        accessToken,
        dossierId: dossier?.dossier_id ?? 0,
      }),
    ).then(() => {
      dispatch(setPostMissingContractor(true));
      dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
      setIsSubmitting(false);
    });
  };

  const fuzzyOptions = {
    keys: [{ name: "label", weight: 1.0 }],
    valueKey: "label",
    maxPatternLength: 8,
    includeScore: true,
    maxResults: 25,
    threshold: 0.6,
  };

  if (renderCondition) {
    return (
      <>
        <div
          className="s-appointment__group border-color-primary"
          ref={scrollRef}
        >
          <h1 className="s-appointment__group__title">
            Wat is de naam van jouw verzekeringsmaatschappij?
          </h1>
          {contractors && (
            <div className="c-form__select react-select">
              <FormSelectFuzzy
                fuzzyOptions={fuzzyOptions}
                wait={300}
                value={insurance.activeContractorId ?? ""}
                options={contractors.map((insurance: ContractorInterface) => {
                  return {
                    value: insurance.id,
                    label: insurance.name,
                  };
                })}
                onChange={(option: { value: string; label: string }) => {
                  dispatch(
                    setInsuranceActiveContractor(parseInt(option.value)),
                  );
                  handleContractorClick(parseInt(option.value));
                }}
              />
              <p style={{ marginTop: "1rem" }}>
                Meld de schade ook direct bij de verzekeringsmaatschappij, zo
                kunnen we je sneller van dienst zijn
              </p>
            </div>
          )}
        </div>
        {!insurance.activeContractorId && (
          <>
            <div className="s-appointment__group border-color-primary">
              <h1 className="s-appointment__group__title">
                Staat je verzekeringsmaatschappij er niet tussen?
              </h1>
              <div className="c-form__buttons c-form__buttons--row">
                <button
                  onClick={handleContinueWithoutContractor}
                  className="c-form__buttons__single--full-width c-form__buttons__single border-color-primary border-color-primary-hover"
                >
                  <span className="font-face-primary">Ga verder</span>
                </button>
              </div>
            </div>
          </>
        )}

        {missingContractor && !insurance.activeContractorId && (
          <div className="s-appointment__group border-color-primary">
            <h1 className="s-appointment__group__title">
              Missende verzekeringsmaatschappij
            </h1>
            <div className="c-form__group c-form__group--row">
              <input
                type="text"
                value={missingContractorName}
                onChange={handleMissingContractorNameChange}
                placeholder="Naam maatschappij"
              />
            </div>
            <div className="c-form__group c-form__group--row">
              <button
                onClick={handleMissingContractorSubmit}
                className="c-form__buttons__single--full-width c-form__buttons__single border-color-primary border-color-primary-hover"
              >
                <span className="font-face-primary">Ga verder</span>
              </button>
            </div>
          </div>
        )}
      </>
    );
  } else return null;
};

export default VehicleInformationInsurance;
