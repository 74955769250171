import {
  setFundingType,
  setLeaseActiveContractor,
  setLeaseType,
  setMissingContractor,
  setMissingContractorName,
  setPostMissingContractor,
} from "../vehicleInformationSlice";
import React, { useEffect, useRef } from "react";
import { setDossierLoading, updateDossier } from "../../../app/rootReducer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLazyGetContractorsByTypeQuery } from "../../apiData/apiDataSlice";
import ContractorInterface from "../../../common/interfaces/ContractorInterface";
import { setGeneralStep } from "../../helpType/helpTypeSlice";
import { GeneralStepsEnum } from "../../../common/constants/GeneralStepsEnum";
import { PatchStepsEnum } from "../../../common/constants/PatchStepsEnum";
import { unsetAppointment } from "../../appointment/appointmentSlice";
import { useParams } from "react-router-dom";
import { fundingTypeEnum } from "../../../types/fundingTypeEnum";
import { dossierTypeEnum } from "../../../types/dossierTypeEnum";
import { postMissingContractorName } from "../../../common/api/Dossier/postMissingContractorMail";
import FormSelectFuzzy from "../../../common/components/form/FormSelectFuzzy";

const VehicleInformationLease = () => {
  const dispatch = useAppDispatch();

  const { fundingType, lease } = useAppSelector(
    (state) => state.vehicleInformation,
  );
  const { dossier, dossierCreating, registrationLink } = useAppSelector(
    (state) => state.root,
  );
  const { missingContractor, missingContractorName, postMissingContractor } =
    useAppSelector((state) => state.vehicleInformation);

  const [trigger, { data: contractors }] = useLazyGetContractorsByTypeQuery();

  const renderCondition =
    dossier &&
    dossier.car_model &&
    dossier.car_brand &&
    lease &&
    !dossierCreating;

  const scrollRef = useRef<HTMLDivElement[]>([]);

  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      if (slug === "athlon") {
        dispatch(setFundingType(fundingTypeEnum.Lease));
      }
      if (slug === "unigarant") {
        dispatch(setFundingType(fundingTypeEnum.Particulier));
      }
    }
  }, [slug]);

  useEffect(() => {
    scrollRef.current[0]?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
    scrollRef.current[2]?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  }, [
    fundingType,
    lease.activeContractorId,
    contractors,
    missingContractor,
    postMissingContractor,
  ]);

  useEffect(() => {
    dispatch(unsetAppointment());
    if (fundingType) {
      switch (fundingType) {
        case fundingTypeEnum.Lease:
          dispatch(setGeneralStep(GeneralStepsEnum.Lease));
          dispatch(
            updateDossier({
              data: { lease_car: 1 },
              method: PatchStepsEnum.PatchLeaseCar,
            }),
          ).then((result) => {
            dispatch(setDossierLoading(true));
            trigger({
              type: "lease",
              dossierId: result.payload.dossier_id,
            }).then(() => {
              dispatch(setDossierLoading(false));
            });
          });
          break;
        case fundingTypeEnum.Particulier:
          dispatch(setGeneralStep(GeneralStepsEnum.Lease));
          dispatch(
            updateDossier({
              data: { lease_car: 0 },
              method: PatchStepsEnum.PatchLeaseCar,
            }),
          ).then((result) => {
            dispatch(setDossierLoading(true));
            trigger({
              type: "insurance",
              dossierId: result.payload.dossier_id,
            }).then(() => {
              dispatch(setDossierLoading(false));
              dispatch(setGeneralStep(GeneralStepsEnum.Insurance));
            });
          });
          break;
        case fundingTypeEnum.Self:
          dispatch(setGeneralStep(GeneralStepsEnum.Lease));
          dispatch(
            updateDossier({
              data: { lease_car: 0 },
              method: PatchStepsEnum.PatchLeaseCar,
            }),
          ).then(() => {
            dispatch(
              updateDossier({
                data: { contractor_id: null },
                method: PatchStepsEnum.PatchContractor,
              }),
            );
            dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
          });
          break;
      }

      dispatch(unsetAppointment());
    }
  }, [fundingType]);

  useEffect(() => {
    if (!registrationLink.private_lease && !registrationLink.business_lease) {
      handleFundingTypeClick(fundingTypeEnum.Particulier);
    }
  }, [registrationLink.private_lease, registrationLink.business_lease]);

  const handleContractorClick = (activeContractorId: number) => {
    dispatch(setGeneralStep(GeneralStepsEnum.Lease));
    dispatch(unsetAppointment());

    if (typeof activeContractorId !== "undefined" && activeContractorId) {
      dispatch(
        updateDossier({
          data: { contractor_id: activeContractorId },
          method: PatchStepsEnum.PatchContractor,
        }),
      ).then(() => {
        if (
          registrationLink.business_lease !== registrationLink.private_lease
        ) {
          dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
          return;
        }

        dispatch(setGeneralStep(GeneralStepsEnum.Lease));
      });
    }
  };

  const handleLeaseTypeClick = (type: string) => {
    if (typeof type !== "undefined" && fundingType === fundingTypeEnum.Lease) {
      dispatch(setGeneralStep(GeneralStepsEnum.Lease));
      dispatch(unsetAppointment());
      dispatch(
        updateDossier({
          data: { lease_type_code: type },
          method: PatchStepsEnum.PatchLeaseTypeCode,
        }),
      ).then(() => {
        dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
      });
    }
  };

  const handleContinueWithoutContractor = () => {
    dispatch(setMissingContractor(true));
  };

  const handleMissingContractorNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setMissingContractorName(event.target.value));
  };

  const handleMissingContractorSubmit = () => {
    const localDossierUuidKey = slug + "-dossier_uuid";
    const localDossierAccessTokenKey = slug + "-dossier_access_token";
    const uuid = localStorage.getItem(localDossierUuidKey) + "";
    const accessToken = localStorage.getItem(localDossierAccessTokenKey) + "";

    if (!postMissingContractor) {
      dispatch(setPostMissingContractor(true));
      dispatch(
          postMissingContractorName({
            name: missingContractorName,
            uuid,
            accessToken,
            dossierId: dossier?.dossier_id ?? 0,
          }),
      ).then(() => {
        dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
      });
    }
  };

  const handleFundingTypeClick = (type: string) => {
    dispatch(setFundingType(type));
    dispatch(setMissingContractor(false));
    dispatch(setPostMissingContractor(false));
    dispatch(unsetAppointment());
  };

  const fuzzyOptions = {
    keys: [{ name: "label", weight: 1.0 }],
    valueKey: "label",
    maxPatternLength: 8,
    includeScore: true,
    maxResults: 25,
    threshold: 0.6,
  };

  useEffect(() => {
    if (fundingType === fundingTypeEnum.Lease) {
      if (registrationLink.business_lease && !registrationLink.private_lease) {
        dispatch(setLeaseType("business"));
        handleLeaseTypeClick("business");
      }
      if (registrationLink.private_lease && !registrationLink.business_lease) {
        dispatch(setLeaseType("private"));
        handleLeaseTypeClick("private");
      }
    }

    if (
      registrationLink.private_insurance &&
      !registrationLink.private_lease &&
      !registrationLink.business_lease
    ) {
      handleFundingTypeClick(fundingTypeEnum.Particulier);
    }
    if (
      !registrationLink.private_insurance &&
      (registrationLink.private_lease || registrationLink.business_lease)
    ) {
      handleFundingTypeClick(fundingTypeEnum.Lease);
    }
  }, [
    fundingType,
    registrationLink.business_lease,
    registrationLink.private_lease,
    registrationLink.private_insurance,
  ]);

  if (
    renderCondition &&
    typeof dossier.appointment_type !== "string" &&
    dossier?.appointment_type?.code !== dossierTypeEnum.CaravanRepair
  ) {
    return (
      <>
        {/** TEMP FIX */}
        {window.location.pathname !== "/athlon" &&
          window.location.pathname !== "/unigarant" &&
          (registrationLink.private_lease || registrationLink.business_lease) &&
          registrationLink.private_insurance && (
            <div className="s-appointment__group border-color-primary">
              <h1 className="s-appointment__group__title">
                Lease of particulier
              </h1>
              <div className="c-form__buttons c-form__buttons--row">
                <button
                  onClick={() => handleFundingTypeClick(fundingTypeEnum.Lease)}
                  className={
                    fundingType === fundingTypeEnum.Lease
                      ? "c-form__buttons__single border-color-primary border-color-primary-hover button-active"
                      : "c-form__buttons__single border-color-primary border-color-primary-hover"
                  }
                >
                  <span className="font-face-primary">Lease</span>
                </button>

                <button
                  onClick={() =>
                    handleFundingTypeClick(fundingTypeEnum.Particulier)
                  }
                  className={
                    fundingType === fundingTypeEnum.Particulier
                      ? "c-form__buttons__single border-color-primary border-color-primary-hover button-active"
                      : "c-form__buttons__single border-color-primary border-color-primary-hover"
                  }
                >
                  <span className="font-face-primary">Particulier</span>
                </button>

                <p>
                  Ik wil zelf de schade betalen klik
                  <button
                    onClick={() =>
                      dispatch(setFundingType(fundingTypeEnum.Self))
                    }
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      border: "none",
                      background: "none",
                    }}
                  >
                    hier
                  </button>
                </p>
              </div>
            </div>
          )}

        {fundingType === fundingTypeEnum.Lease && contractors && (
          <>
            <div
              className="s-appointment__group border-color-primary"
              ref={(el) => {
                if (el) {
                  scrollRef.current[0] = el;
                }
              }}
            >
              <h1 className="s-appointment__group__title">
                Wat is de naam van jouw leasemaatschappij?
              </h1>
              <div className="c-form__select react-select">
                <FormSelectFuzzy
                  fuzzyOptions={fuzzyOptions}
                  wait={300}
                  value={lease.activeContractorId ?? ""}
                  options={contractors.map((insurance: ContractorInterface) => {
                    return {
                      value: insurance.id,
                      label: insurance.name,
                    };
                  })}
                  onChange={(option: { value: string; label: string }) => {
                    dispatch(setLeaseActiveContractor(parseInt(option.value)));
                    handleContractorClick(parseInt(option.value));
                  }}
                />
                <p style={{ marginTop: "1rem" }}>
                  Meld de schade ook direct bij de leasemaatschappij, zo kunnen
                  we je sneller van dienst zijn
                </p>
              </div>
            </div>

            {!lease.activeContractorId && (
              <>
                <div className="s-appointment__group border-color-primary">
                  <h1 className="s-appointment__group__title">
                    Staat je leasemaatschappij er niet tussen?
                  </h1>
                  <div className="c-form__buttons c-form__buttons--row">
                    <button
                      onClick={handleContinueWithoutContractor}
                      className="c-form__buttons__single--full-width c-form__buttons__single border-color-primary border-color-primary-hover"
                    >
                      <span className="font-face-primary">Ga verder</span>
                    </button>
                  </div>
                </div>
              </>
            )}

            {missingContractor && !lease.activeContractorId && (
              <div className="s-appointment__group border-color-primary">
                <h1 className="s-appointment__group__title">
                  Missende leasemaatschappij
                </h1>
                <div className="c-form__group c-form__group--row">
                  <input
                    type="text"
                    value={missingContractorName}
                    onChange={handleMissingContractorNameChange}
                    placeholder="Naam maatschappij"
                  />
                </div>
                <div className="c-form__group c-form__group--row">
                  <button
                    onClick={handleMissingContractorSubmit}
                    className="c-form__buttons__single--full-width c-form__buttons__single border-color-primary border-color-primary-hover"
                  >
                    <span className="font-face-primary">Ga verder</span>
                  </button>
                </div>
              </div>
            )}

            {(!!lease.activeContractorId || postMissingContractor) &&
              registrationLink.business_lease &&
              registrationLink.private_lease && (
                <div
                  className="s-appointment__group border-color-primary"
                  ref={(el) => {
                    if (el) {
                      scrollRef.current[1] = el;
                    }
                  }}
                >
                  <h1 className="s-appointment__group__title">
                    Privé of zakelijk
                  </h1>
                  <div className="c-form__buttons c-form__buttons--row">
                    <button
                      onClick={() => {
                        dispatch(setLeaseType("private"));
                        handleLeaseTypeClick("private");
                      }}
                      className={
                        lease.type === "private"
                          ? "c-form__buttons__single border-color-primary border-color-primary-hover button-active"
                          : "c-form__buttons__single border-color-primary border-color-primary-hover"
                      }
                    >
                      <span className="font-face-primary">Privé lease</span>
                    </button>

                    <button
                      onClick={() => {
                        dispatch(setLeaseType("business"));
                        handleLeaseTypeClick("business");
                      }}
                      className={
                        lease.type === "business"
                          ? "c-form__buttons__single border-color-primary border-color-primary-hover button-active"
                          : "c-form__buttons__single border-color-primary border-color-primary-hover"
                      }
                    >
                      <span className="font-face-primary">Zakelijk lease</span>
                    </button>
                  </div>
                    { lease.type === 'private' &&
                      <p style={{ marginTop: "1rem" }}>
                        Vervangend vervoer is bij private lease niet altijd
                        standaard inbegrepen of gratis. Controleer de voorwaarden
                        van uw leaseovereenkomst.
                      </p>
                    }
                </div>
              )}
          </>
        )}
      </>
    );
  } else return null;
};

export default VehicleInformationLease;
